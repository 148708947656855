import React from "react";
import {
  Container,
  Box,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "gatsby";

function Glossary(props) {
  const { items } = props;

  const groups = {
    a: [],
    b: [],
    c: [],
    d: [],
    e: [],
    f: [],
    g: [],
    h: [],
    i: [],
    j: [],
    k: [],
    l: [],
    m: [],
    n: [],
    o: [],
    p: [],
    q: [],
    r: [],
    s: [],
    t: [],
    u: [],
    v: [],
    w: [],
    x: [],
    y: [],
    z: [],
    others: [],
  };

  items.forEach((item) => {
    let m = item.name[0].toLowerCase();
    if (groups[m]) {
      groups[m].push(item);
    } else {
      groups["others"].push(item);
    }
  });

  return (
    <React.Fragment>
      <Box bgcolor="#ecf0f2" height="100%">
        <Container maxWidth={false}>
          <Paper
            square
            style={{
              padding: "10px",
              marginTop: "7px",
            }}
          >
            <Typography variant="h4">All Brands</Typography>
            <Grid container>
              {Object.keys(groups).map((key, i) => (
                <Grid item key={i} xs={12} sm={6} lg={3} md={3}>
                  <Box style={{ padding: "10px" }}>
                    <Typography style={{
                      fontSize:"22px", 
                      fontWeight:"bold"}}
                      >{key.toUpperCase()}</Typography>
                    <Box>
                      <Grid container direction="column">
                        {groups[key].map((group, g) => (
                          <Grid item key={g}>
                            <Link
                              title={`${group.name} Mobile Phones`}
                              to={`/${group.url}/`}
                              style={{
                                lineHeight:"2"
                              }}
                            >
                              {group.name}
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Glossary;
