import React from "react";
import GlossaryPage from "../glossary";
import Layout from "../components/Layout";

function Glossary(props) {
  const { pageContext } = props;

  return (
    <Layout sideMenuCollections={pageContext.sideMenuCollections}>
      <GlossaryPage
        trendings={pageContext.trendings}
        items={pageContext.items}
      />
    </Layout>
  );
}

export default Glossary;
